define(['app'], function(app) {

  var productSingleImage = function() {
    var component = {};

    var _config = {
      selectors: {
        component: '[data-component=productSingleImage]',
        image: '[data-product-single-image]',
        imageSize: 'data-image-size',
        loading: '[data-loading]'
      },
      subscribeChannels: {
        image: 'productSingleImage/newImage',
      },
      classes: {
        loadingShow: 'productSingleImage_loading-show'
      }
    };

    var _init = function(element) {
      component.element = element;
      component.loading = component.element.querySelector(component.config.selectors.loading);
      component.imageSize = component.element.getAttribute(component.config.selectors.imageSize);

      var imageSubscribe = app.subscribe(component.config.subscribeChannels.image, component.getNewImage);
      component.checkSubscribeMessages(imageSubscribe, component.getNewImage);

      return component;
    };

    var _imageReceived = function(imageData) {
      var newContainer = document.createElement('div');
      newContainer.innerHTML = imageData;
      var newElem = newContainer.querySelector(component.config.selectors.component);
      var newImg = newElem.querySelector(component.config.selectors.image);
      var oldImg = component.element.querySelector(component.config.selectors.image);

      if (newImg) {
        component.element.removeChild(oldImg);
        component.element.appendChild(newImg);
      }
    };

    var _getNewImage = function(productId) {
      component.showLoading();
      var imageSize = component.imageSize ? '?imageSize=' + component.imageSize : '';
      app.ajax.get({
        url: '/' + productId + '.image' + imageSize,
        success: component.imageSuccessHandler,
        error: component.imageErrorHandler
      });
    };

    var _imageSuccessHandler = function(response) {
      component.imageReceived(response);
      component.hideLoading();
    };

    var _imageErrorHandler = function() {
      component.hideLoading();
      console.error(
        'ERROR: Could not retrieve child product image');
    };

    var _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        var lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    var _showLoading = function() {
      app.element.addClass(component.config.classes.loadingShow, component.loading);
    };

    var _hideLoading = function() {
      app.element.removeClass(component.config.classes.loadingShow, component.loading);
    };

    component.init = _init;
    component.config = _config;
    component.imageReceived = _imageReceived;
    component.getNewImage = _getNewImage;
    component.imageSuccessHandler = _imageSuccessHandler;
    component.imageErrorHandler = _imageErrorHandler;
    component.checkSubscribeMessages = _checkSubscribeMessages;
    component.showLoading = _showLoading;
    component.hideLoading = _hideLoading;

    return component;
  };

  return productSingleImage;
});
